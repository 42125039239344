<template>
  <div class="row">
    <section class="py-5 white my-3">
      <div class="col-md-10 mx-auto">
        <img src="@/assets/images/icon/banner.jpg" class="img-kategori"/>
        <h2 class="font-weight-bold pb-2">Siap Kerja Dengan Kartu Prakerja</h2>
        <p>
          PT Yureka Edukasi Cipta yang memiliki nama brand YEC CO ID berdiri pada tahun 2010 dengan nama awal Eureka Tour, sebuah layanan jasa tour spesialis Kampung Inggris. Saat ini YEC fokus membantu setiap peserta menjadi individu lebih baik. Tidak hanya sebatas menjadi jasa tour, namun juga menjadi konsultan pendidikan dan media yang membantu menjadikan diri lebih baik dengan kemudahan akses informasi dan program pelatihan terkait tes TOEFL, IELTS, Persiapan Kerja dan Pembelajaran Bahasa Inggris Sehari-hari.
        </p>
        <p>
          Serta pada tahun 2020, YEC CO ID bergabung bersama program Kartu Prakerja, memberikan pelatihan kepada peserta kartu prakerja yang memiliki keinginan untuk mengembangkan keahlian agar lebih percaya diri terjun ke dunia kerja, maupun membuat usaha.
        </p>
        <div class="tab-prakerja">
          <div class="row">
            <div class="col-md-4">
              <v-card
                class="mx-auto"
              >
                <v-list id="tab-ver">
                  <v-list-item-group>
                    <v-list-item href="#program">
                      <v-list-item-content>
                        <v-list-item-title>Program Kartu Prakerja</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item href="#langkah">
                      <v-list-item-content>
                        <v-list-item-title>Langkah Mengikuti Program Prakerja</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item href="#mengapa">
                      <v-list-item-content>
                        <v-list-item-title>Mengapa Harus Memilih Kelas Pelatihan di YEC.CO.ID?</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item href="#kelas">
                      <v-list-item-content>
                        <v-list-item-title>Kelas-kelas Yang Dirancang oleh YEC.CO.ID</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item href="#faq">
                      <v-list-item-content>
                        <v-list-item-title>FAQ</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </div>
            <div class="col-md-8">
              <div id="program" class="mb-4">
                <h2 class="font-weight-bold pb-2">Siap Kerja Dengan Kartu Prakerja</h2>
                <p>Apa itu program kartu prakerja?</p>
                <p>Program Kartu Prakerja adalah program pengembangan kompetensi kerja dan kewirausahaan yang ditujukan untuk pencari kerja, pekerja/buruh yang terkena pemutusan hubungan kerja, dan/atau pekerja/buruh yang membutuhkan peningkatan kompetensi, termasuk pelaku usaha mikro dan kecil. Kami percaya bahwa masyarakat Indonesia sesungguhnya ingin selalu meningkatkan kemampuannya. Program ini didesain sebagai sebuah produk dan dikemas sedemikian rupa agar memberikan nilai bagi pengguna sekaligus memberikan nilai bagi sektor swasta.</p>
                <p>Jalan digital melalui marketplace dipilih untuk memudahkan pengguna mencari, membandingkan, memilih dan memberi evaluasi. Hanya dengan cara ini, produk bisa terus diperbaiki, tumbuh dan relevan. Menggandeng pelaku usaha swasta, program ini adalah wujud kerjasama pemerintah dan swasta dalam melayani masyarakat dengan semangat gotong royong demi SDM unggul, Indonesia maju. <a href="https://www.prakerja.go.id/tentang-prakerja"> (https://www.prakerja.go.id/tentang-prakerja) </a>.</p>
                <p>
                  Program ini bisa diikuti seluruh masyarakat Indonesia yang memiliki kriteria berikut:
                  <ol>
                    <li>Warga negara Indonesia berusia paling rendah 18 (delapan belas) tahun</li>
                    <li>Tidak sedang mengikuti pendidikan formal</li>
                  </ol>
                </p>
                <p class="mb-0">Sedangkan, siapa saja yang tidak bisa mengikuti program Kartu Prakerja, diantaranya:</p>
                <ol>
                  <li>Pejabat Negara</li>
                  <li>Pimpinan dan Anggota Dewan Perwakilan Rakyat Daerah</li>
                  <li>Aparatur Sipil Negara</li>
                  <li>Prajurit Tentara Nasional Indonesia</li>
                  <li>Anggota Kepolisian Negara Republik Indonesia</li>
                  <li>Kepala Desa dan perangkat desa</li>
                  <li>Direksi, Komisaris, dan Dewan Pengawas pada badan usaha milik negara atau badan usaha milik daerah</li>
                </ol>
                <p>
                  Keuntungan mengikuti Program Kartu Prakerja <br>
                  Mendapatkan subsidi <b> non tunai </b> sebesar <b> 1 juta rupiah </b>, yang dapat digunakan untuk membeli 
                  pelatihan online
                </p>
                <p>
                  Setelah mengikuti pelatihan, akan mendapatkan insentif sebesar <b> 600 ribu sebanyak 4 kali </b> dan <b> 150 ribu </b> dari pengisian survey. Info lebih lanjut mengenai program ini dapat dilihat di <a href="https://www.prakerja.go.id"> www.prakerja.go.id </a>. Contact center: <b class="primar--text"> 08001503001 </b>
                </p>
              </div>
              <div id="langkah" class="mb-4">
                <h2 class="font-weight-bold mb-2">Langkah Mengikuti Program Kartu Prakerja</h2>
                <ol class="mb-3">
                  <img src="@/assets/images/icon/icon-1.png" class="w-100">
                  <li>
                    <b>Daftar Akun</b>
                    <p>
                      Silahkan melakukan pendaftaran di <br>
                      Daftar Kartu Prakerja Sekarang (diisi link url berikut <a href="https://dashboard.prakerja.go.id/daftar"> https://dashboard.prakerja.go.id/daftar</a> )
                      Siapkan e-mail, NIK, nomor KK dan nomor HP yang masih aktif untuk mendaftar.
                      Pastikan Data Kamu Sesuai.
                    </p>
                  </li>
                  <img src="@/assets/images/icon/icon-2.png" class="w-100">
                  <li>
                    <b>Ikut Seleksi</b>
                    <ul>
                      <li>Ikuti tes motivasi & kemampuan dasar untuk bisa mengikuti seleksi gelombang</li>
                      <li>Jangan lupa Klik “Gabung”, saat gelombang dibuka</li>
                      <li>Kemudian tunggu pengumuman hasilnya.</li>
                    </ul>
                    <p class="font-weight-bold">Apabila kamu lolos seleksi</p>
                  </li>
                  <img src="@/assets/images/icon/icon-3.png" class="w-100">
                  <li>
                    <b>Sambungkan Rekening/E-wallet</b>
                    <p>
                      Sambungkan rekening/e-wallet di salah satu Mitra Pembayaran Program Kartu Prakerja. Pastikan pemilik rekening/e-wallet yang disambungkan sama dengan pemilik akun prakerja
                    </p>
                  </li>
                  <img src="@/assets/images/icon/icon-4.png" class="w-100">
                  <li>
                    <b>Pilih Pelatihan</b>
                    <p>
                      Kamu dapat Membeli Pelatihanmu di Mitra Platform Digital yang bekerja sama dengan prakerja salah satunya YEC CO ID kemudian bayar dengan Kartu Prakerja.
                    </p>
                  </li>
                  <img src="@/assets/images/icon/icon-5.png" class="w-100">
                  <li class="mb-2">
                    <b>Selesaikan Pelatihan dan Dapatkan Sertifikat</b>
                  </li>
                  <img src="@/assets/images/icon/icon-6.png" class="w-100">
                  <li class="mb-2">
                    <b>Beri Rating dan Ulasan di Pelatihan yang kamu ikuti</b>
                  </li>
                  <img src="@/assets/images/icon/icon-7.png" class="w-100">
                  <li>
                    <b>Mendapatkan Insentif Biaya Mencari Kerja Cair</b>
                    <p>
                      Pastikan pemilik rekening/e-wallet  sama dengan pemilik akun prakerja.
                      Setelah sertifikat muncul di Dashboard Prakerja maka akan mendapatkan jadwal pencairan insentif Rp600rb/bulan selama 4 bulan. Insentif biasanya keluar sesuai dengan jadwal.
                    </p>
                  </li>
                  <img src="@/assets/images/icon/icon-8.png" class="w-100">
                  <li>
                    <b>Mendapatkan Insentif Pengisian Survei Evaluasi</b>
                    <p>
                      Isi 3 survei yang diberikan Program Kartu Prakerja dan dapatkan insentif Rp50 ribu untuk setiap survei.
                    </p>
                  </li>
                </ol>
                <p class="font-weight-bold mb-1">Beli Pelatihanmu Disini!</p>
                <p>YEC CO ID berafiliasi dengan beberapa Digital Platform, yaitu  Karier.mu, Tokopedia, Pintaria, dan Pijar Mahir</p>
                <div class="row">
                  <div class="col-md-3">
                    <a href="https://prakerja.karier.mu/mitra/yec-co-id" target="_blank">                      
                      <img src="@/assets/images/icon/image-5.png">
                    </a>
                  </div>
                  <div class="col-md-3">
                    <a href="https://www.tokopedia.com/kartu-prakerja" target="_blank">                      
                      <img src="@/assets/images/icon/image-6.png">
                    </a>
                  </div>
                  <div class="col-md-3">
                    <a href="https://pintaria.com/kartuprakerja" target="_blank">                      
                      <img src="@/assets/images/icon/image-7.png">
                    </a>
                  </div>
                  <div class="col-md-3">
                    <a href="https://pijarmahir.id/category/Kartu%20Prakerja?id=78&page=1&limit=10&sort=0" target="_blank">                      
                      <img src="@/assets/images/icon/image-8.png">
                    </a>
                  </div>
                </div>
              </div>
              <div id="mengapa" class="mb-4">
                <h2 class="font-weight-bold pb-2">Mengapa Harus Memilih Kelas Pelatihan di YEC.CO.ID ?</h2>
                <v-list three-line>
                  <template>                              
                    <v-list-item>
                      <v-list-item-avatar width="64" height="64">
                        <img src="@/assets/images/icon/Group-1.png">
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title class="mb-1 font-weight-bold">Instruktur Berkompeten</v-list-item-title>                        
                         <v-list-item-subtitle>Instruktur kelas berupa putra-putri daerah yang berkompeten di bidangnya.</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-avatar width="64" height="64">
                        <img src="@/assets/images/icon/Group-2.png">
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title class="mb-1 font-weight-bold">Grup Eksklusif</v-list-item-title>                        
                         <v-list-item-subtitle>Grup Pengembangan Karir GRATIS dengan webinar eksklusif secara berkala.</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-avatar width="64" height="64">
                        <img src="@/assets/images/icon/Group-3.png">
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title class="mb-1 font-weight-bold">Admin</v-list-item-title>                        
                         <v-list-item-subtitle>Admin cepat tanggap di semua media sosial.</v-list-item-subtitle>
                         <span class="mb-2">
                            <a
                              href="https://www.instagram.com/yec.prakerja/"
                              target="_blank"
                            >
                              <img
                                src="@/assets/images/instagram 1.png"
                                alt="Avatar"
                                class="mr-2"
                                style="width: 25px"
                              />
                            </a>
                            <a
                              href="https://t.me/joinchat/HOIYkUuvNkC4pyuN2E-z4w"
                              target="_blank"
                            >
                              <img
                                src="@/assets/images/telegram 1.png"
                                alt="Avatar"
                                class="mr-2"
                                style="width: 25px"
                              />
                            </a>
                            <a href="http://facebook.com/groups/prakerja.yec" 
                              target="_blank">
                              <img
                                src="@/assets/images/FB.png"
                                alt="Avatar"
                                class="mr-2"
                                style="width: 25px"
                              />
                            </a>
                            <a
                              href="https://www.tiktok.com/@panduanprakerja?lang=en"
                              target="_blank"
                            >
                              <img
                                src="@/assets/images/tiktok.png"
                                alt="Avatar"
                                class=""
                                style="width: 25px"
                              />
                            </a>
                          </span>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
              </div>
              <div id="kelas" class="mb-4">
                <h2 class="font-weight-bold pb-2">Kelas-kelas Yang Dirancang oleh YEC.CO.ID</h2>
                <div class="col-md-10 pa-0 mb-3">
                  <v-card class="mx-auto px-0" elevation="0">
                    <img
                      src="@/assets/images/icon/po.png"
                      class="img-kategori"
                    />

                    <v-card-subtitle class="px-0 pb-1">
                      <h3 class="color-black">Menjual Produk melalui Media Sosial bagi Pedagang Melalui Internet</h3>
                    </v-card-subtitle>

                    <v-card-text class=" px-0 pt-0">
                      <p class="mb-0 fs-14">
                        Menjual Produk melalui Media Sosial bagi Pedagang Melalui Internet adalah kelas yang disusun khusus untuk peserta yang ingin masuk dalam dunia bisnis menjadi penjual online. Pelatihan ini menggunakan metode ajar SPL (Self Paced Learning), dengan menggunakan metode ajar ceramah, peragaan, dan membaca e-book. Kelas ini merujuk pada KBJI nomor 5244.02 dan Kompetensi berdasarkan pada SKKNI. 
                      </p>
                    </v-card-text>              
                  </v-card>
                </div>
                <div class="col-md-10 pa-0 mb-3">
                  <v-card class="mx-auto px-0" elevation="0">
                    <img
                      src="@/assets/images/icon/cc.png"
                      class="img-kategori"
                    />

                    <v-card-subtitle class="px-0 pb-1">
                      <h3 class="color-black">Mengembangkan Akun YouTube dan Tiktok bagi Seniman Digital</h3>
                    </v-card-subtitle>

                    <v-card-text class=" px-0 pt-0">
                      <p class="mb-0 fs-14">
                        Program ini (Mengembangkan Akun YouTube dan Tiktok bagi Seniman Digital) adalah kelas yang disusun khusus untuk peserta yang ingin menjadi seorang content creator pada platform Tiktok dan Youtube. Program ini disusun menggunakan sumber rujukan KBJI nomor 2166.02 dan kompetensi merujuk pada SKKNI. Metode ajar yang digunakan dalam pelatihan ini adalah Hybrid, dimana akan ada beberapa metode ajar yaitu ceramah, peragaan, webinar, dan memahami e-book.
                      </p>
                    </v-card-text>              
                  </v-card>
                </div>
                <div class="col-md-10 pa-0 mb-3">
                  <v-card class="mx-auto px-0" elevation="0">
                    <img
                      src="@/assets/images/icon/pd.png"
                      class="img-kategori"
                    />

                    <v-card-subtitle class="px-0 pb-1">
                      <h3 class="color-black">Mengolah Dokumen dengan Ms.Word & Ms. Excel untuk Petugas Input Data</h3>
                    </v-card-subtitle>

                    <v-card-text class=" px-0 pt-0">
                      <p class="mb-0 fs-14">
                        Mengolah Dokumen dengan Ms.Word & Ms. Excel untuk Petugas Input Data adalah kelas yang disusun khusus untuk peserta yang ingin menjadi petugas input data dengan meningkatkan ketrampilan mengolah dokumen dalam Ms. Word dan Ms. Excel. Program ini disusun menggunakan sumber rujukan KBJI dan kompetensi merujuk pada SKKNI. Metode ajar yang digunakan dalam pelatihan ini adalah SPL (Self Paced Learning), dengan menggunakan metode ajar yaitu ceramah, peragaan, dan memahami e-book.
                      </p>
                    </v-card-text>              
                  </v-card>
                </div>
                <div class="col-md-10 pa-0 mb-3">
                  <v-card class="mx-auto px-0" elevation="0">
                    <img
                      src="@/assets/images/icon/Kopi.png"
                      class="img-kategori"
                    />

                    <v-card-subtitle class="px-0 pb-1">
                      <h3 class="color-black">Mengembangkan Usaha menjadi Penjual Minuman Kopi</h3>
                    </v-card-subtitle>

                    <v-card-text class=" px-0 pt-0">
                      <p class="mb-0 fs-14">
                        Program ini (Mengembangkan Usaha menjadi Penjual Minuman Kopi) adalah kelas yang disusun khusus bagi peserta yang ingin menggeluti bidang penjualan usaha kopi. Program ini disusun menggunakan sumber rujukan KBJI nomor 5212.01 dan kompetensi merujuk pada SKKNI. Metode ajar yang digunakan dalam pelatihan ini adalah SPL (Self Paced Learning), dimana akan ada beberapa metode ajar yaitu ceramah, peragaan, dan memahami e-book.
                      </p>
                    </v-card-text>              
                  </v-card>
                </div>
                <div class="col-md-10 pa-0 mb-3">
                  <v-card class="mx-auto px-0" elevation="0">
                    <img
                      src="@/assets/images/icon/Soto.png"
                      class="img-kategori"
                    />

                    <v-card-subtitle class="px-0 pb-1">
                      <h3 class="color-black">Mengembangkan Usaha menjadi Penjual Makanan Soto</h3>
                    </v-card-subtitle>

                    <v-card-text class=" px-0 pt-0">
                      <p class="mb-0 fs-14">
                        Mengembangkan Usaha menjadi Penjual Makanan Soto adalah kelas yang disusun khusus untuk peserta yang ingin menjadi Penjual Makanan khususnya Soto Banjar dan Soto Lamongan. Program ini disusun menggunakan sumber rujukan KBJI dan kompetensi merujuk pada SKKNI. Metode ajar yang digunakan dalam pelatihan ini adalah SPL (Self Paced Learning), dengan menggunakan metode ajar ceramah, peragaan, dan membaca e-book.
                      </p>
                    </v-card-text>              
                  </v-card>
                </div>
                <div class="col-md-10 pa-0 mb-3">
                  <v-card class="mx-auto px-0" elevation="0">
                    <img
                      src="@/assets/images/icon/International.png"
                      class="img-kategori"
                    />

                    <v-card-subtitle class="px-0 pb-1">
                      <h3 class="color-black">Mengembangkan Usaha Menjadi Penjual Makanan Barat</h3>
                    </v-card-subtitle>

                    <v-card-text class=" px-0 pt-0">
                      <p class="mb-0 fs-14">
                        Program ini (Mengembangkan Usaha Menjadi Penjual Makanan Barat) adalah kelas yang disusun khusus untuk peserta yang ingin mengembangkan usaha kuliner makanan barat Steak dan Pizza Teflon. Metode ajar yang digunakan adalah SPL (Self Paced Learning), dengan menggunakan metode ajar ceramah, peragaan, dan membaca e-book.Kelas ini merujuk pada KBJI dan Kompetensi berdasarkan pada SKKNI.
                      </p>
                    </v-card-text>              
                  </v-card>
                </div>
                <div class="col-md-10 pa-0 mb-3">
                  <v-card class="mx-auto px-0" elevation="0">
                    <img
                      src="@/assets/images/icon/aneka-kue.png"
                      class="img-kategori"
                    />

                    <v-card-subtitle class="px-0 pb-1">
                      <h3 class="color-black">Mengembangkan Usaha menjadi Penjual Aneka Kue</h3>
                    </v-card-subtitle>

                    <v-card-text class=" px-0 pt-0">
                      <p class="mb-0 fs-14">
                        Mengembangkan Usaha menjadi Penjual Aneka Kue adalah kelas yang disusun khusus untuk peserta yang ingin menjadi Penjual Kue khususnya kue bolu, donat, dan kue kekinian “dessert box”. Program ini disusun menggunakan sumber rujukan KBJI dan kompetensi merujuk pada SKKNI. Metode ajar yang digunakan dalam pelatihan ini adalah SPL (Self Paced Learning), dengan menggunakan metode ajar ceramah, peragaan, dan membaca e-book.
                      </p>
                    </v-card-text>              
                  </v-card>
                </div>
                <div class="col-md-10 pa-0 mb-3">
                  <v-card class="mx-auto px-0" elevation="0">
                    <img
                      src="@/assets/images/icon/pm.png"
                      class="img-kategori"
                    />

                    <v-card-subtitle class="px-0 pb-1">
                      <h3 class="color-black">Pemrograman Website Pemula dan Desainer Website</h3>
                    </v-card-subtitle>

                    <v-card-text class=" px-0 pt-0">
                      <p class="mb-0 fs-14">
                       Program ini disusun bagi peserta yang tertarik belajar pembuatan website dari nol tanpa perlu tahu terkait koding dan bahasa pemrograman. Selain membuat website, peserta juga akan mempelajari langkah-langkah mendesain website dan suatu saat ingin bekerja sebagai pekerja lepas (freelancer). Kompetensi yang disasar dari program ini adalah mandiri dan berorientasi pada tindakan.
                      </p>
                    </v-card-text>              
                  </v-card>
                </div>
              </div>
              <div id="faq" class="mb-4">
                <h2 class="font-weight-bold pb-2">FAQ</h2>
                <v-expansion-panels accordion outlined>
                  <v-expansion-panel>
                    <v-expansion-panel-header class="font-weight-bold">Apakah ada batas waktu penggunaan saldo pelatihan?</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      Ya. Kamu bisa menggunakan saldo pelatihan selama 30 (tiga puluh) hari sejak diterima di dashboard prakerjamu. Jika sudah melebihi 30 (tiga puluh) hari dan kamu belum membeli pelatihan, maka kartu prakerja kamu akan dinonaktifkan dan saldo pelatihan kamu akan hangus dan dikembalikan ke Rekening Dana Prakerja. Kamu juga tidak dapat mengikuti kembali program kartu prakerja. 
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header class="font-weight-bold">Apakah saldo pelatihan 1.000.000 bisa dicairkan?</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      Tidak bisa, saldo hanya bisa digunakan untuk membeli pelatihan di platform digital mitra, salah satunya YEC di Sekolah.mu
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header class="font-weight-bold">Apa keunggulan pelatihan di YEC CO ID?</v-expansion-panel-header>
                    <v-expansion-panel-content>
                     <ul>
                        <li>Jika ada masalah saat akses pelatihan, silakan sampaikan ke Grup FB, WA atau TG Prakerja YEC. Admin akan merespon cepat kendala kamu</li>
                        <li>Materi mudah dipahami dan juga disediakan latihan soal</li>
                     </ul>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header class="font-weight-bold">Bagaimana cara membeli kelas YEC CO ID diplatform sekolahmu menggunakan Kartu Prakerja?</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <ol>
                        <li>Klik link berikut: <a href="https://prakerja.karier.mu/mitra/yec-co-id">https://prakerja.karier.mu/mitra/yec-co-id</a></li>
                        <li>Pilih Pelatihan yang kamu minati</li>
                        <li>Bayar dengan Kartu Prakerja pilih pembayaran “Ikuti dengan Kartu Prakerja”</li>
                        <li>Apabila muncul tulisan “kamu belum login”, silahkan login terlebih dahulu</li>
                        <li>Apabila belum memiliki akun, silahkan pilih “Daftar Disini” dan ikuti prosesnya</li>
                        <li>Apabila sudah berhasil login, saatnya kamu mengkonfirmasi pembayaran. Silahkan isi Nomer Kartu Prakerja kamu dan Pilih “Lanjut Pembayaran”</li>
                        <li>Kemudian silahkan mengisi OTP, kode OTP akan dikirimkan melalui SMS</li>
                        <li>Pembelian pelatihan kamu berhasil! Pilih “Mulai Belajar” untuk melihat Pelatihan yang kamu ikuti</li>
                      </ol>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header class="font-weight-bold">Cara mengakses kelas yang sudah dibeli di platform sekolah.mu?</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <ol class="mb-2">
                        <li>Log in di website <a href="https://prakerja.karier.mu ">https://prakerja.karier.mu </a></li>
                        <li>Cek <b>programmu </b></li>
                        <li>Akan ditampilkan pelatihan yang sudah dibeli </li>
                        <li>Klik pelatihan yang sudah dibeli </li>
                        <li>Kerjakan setiap aktivitas hingga <b>tercentang hijau</b> dan progress berjalan hingga <b>100%</b></li>
                      </ol>
                      <p class="font-weight-bold mb-0">
                        Video panduan akses kelas : 
                      </p>
                      <p>
                        <a href="https://www.youtube.com/watch?v=mIXpzouX5_w">https://www.youtube.com/watch?v=mIXpzouX5_w</a>
                      </p>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header class="font-weight-bold">Cara Membeli Pelatihan di Pijar Mahir</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <ol class="mb-2">
                        <li>Silahkan cek terlebih dahulu apakah kamu sudah memiliki akun di Pijar Mahir, apabila belum silahkan lakukan pendaftaran.</li>
                        <li>
                          Apabila sudah memiliki akun Pijar Mahir. Silahkan langsung melakukan beli pelatihan di Pijar Mahir. Berikut langkah-langkahnya:
                          <ul>
                            <li>Pilih pelatihan kamu minati di Pijar Mahir</li>
                            <li>Pilih Beli Sekarang</li>
                            <li>Pilih Metode Pembayaran Menggunakan <b>“Kartu Prakerja”</b></li>
                            <li>Klik tombol <b>“Bayar”</b> untuk melnajutkan pembayaran</li>
                            <li>Peserta akan mendapatkan <b>email status pemesanan pelatihan</b></li>
                            <li>Silahkan Melanjutkan proses pembayaran dengan <b>memasukkan Nomor Kartu Prakerja</b> di Pijar Mahir</li>
                            <li>Masukkan kode OTP</li>
                            <li>Pembayaran Berhasil</li>
                          </ul>
                          <p class="font-weight-bold mb-0">Atau</p>
                          <ul>
                            <li>Pilih pelatihan kamu minati di Pijar Mahir</li>
                            <li>Pilih Beli Sekarang</li>
                            <li>Pilih Metode Pembayaran Menggunakan <b>“Kartu Prakerja”</b></li>
                            <li>Klik tombol <b>“Bayar”</b> untuk melnajutkan pembayaran</li>
                            <li>Cek menu Transaksi untuk melanjutkan pembayaran, klik <b>“lanjutkan pembayaran”</b></li>
                            <li>Apabila ada keterangan Pembayaran Berhasil maka telah berhasil dibayar</li>
                            <li>Untuk melanjutkan ke pelatihan klik “Mulai Belajar”</li>
                          </ul>
                        </li>
                      </ol>                      
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header class="font-weight-bold">Bagaimana Cara Pembelian Kelas Prakerja di Pintaria? </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <ol class="mb-2">
                        <li>Akses web <a href="https://pintaria.com/kartuprakerja">https://pintaria.com/kartuprakerja </a></li>
                        <li>Buat akun di Pintaria dengan klik Daftar/Masuk</li>
                        <li>Lengkapi data diri Anda, kemudian <b>“klik daftar”</b></li>
                        <li>Apabila telah memiliki akun Pintaria, silahkan Log in menggunakan email dan pasword yang telah didaftarkan dilink berikut <a href="https://sso.pintaria.com/">https://sso.pintaria.com/</a> </li>
                        <li>Pilih Menu Kartu Prakerja</li>
                        <li>Schroll kebawah untuk melihat pilihan kursus atau cari di pencarian</li>
                        <li>Pilih kursus yang Anda minati, dengan klik <b>“ikuti kursus”</b></li>
                        <li>Silahkan masukkan No Ponsel Anda</li>
                        <li>Lanjutkan Pembayaran dengan memasukkan ID Prakerja dan Kode OTP kemudian klik bayar</li>
                        <li>Klik <b>“Kelas Saya”</b></li>
                        <li>Silahkan Mengisi survey terlebih dahulu</li>
                        <li>Silahkan <b>“Masuk Kelas”</b> untuk mengakses kelas yg Anda Beli.</li>
                      </ol>
                      <p>Selamat Belajar</p>                      
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header class="font-weight-bold">Bagaimana Cara Reedem Voucher Kelas Prakerja YEC CO ID di Tokopedia? </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <ol class="mb-2">
                        <li>Pastikan sudah memiliki akun di Tokopedia</li>
                        <li>Pilih kursus YEC.CO.ID yang sudah disediakan platform Tokopedia</li>
                        <li>Untuk membantu mencari kursus yang Anda inginkan, dapat menggunakan fitur Search,
                        dengan ketik nama pelatihan sesuai yang kamu inginkan.</li>
                        <li>Klik Beli</li>
                        <li>Pilih metode bayar dengan “Kartu Prakerja”</li>
                        <li>Klik Lanjutkan, dan masukkan nomor kartu prakerja, dan klik bayar</li>
                        <li>Masukan kode verifikasi atau OTP</li>
                        <li>Cek detail transaksi, catat kode voucher dan salin</li>
                        <li>Langkah selanjutnya, </li>
                        <li>Silahkan masuk ke web <a href="https://bakat.yec.co.id/">https://bakat.yec.co.id/</a> </li>
                        <li>Apabila belum mempunyai akun YEC, silahkan daftar akun terlebih dahulu dengan klik <b>“Daftar”</b></li>
                        <li>Setelah memiliki akun, silahkan Log in menggunakan nomer WA dan pasword yang sudah didaftarkan.</li>
                        <li>Silahkan isi data diri Anda</li>
                        <li>Kemudian klik <b>“Kelas Saya”</b> untuk melakukan redeem voucher</li>
                        <li>Masukkan kode voucher kemudian klik <b>“redeem”</b></li>
                        <li>Kelas yang Anda beli akan muncul, silahkan mulai mengikuti kelas Anda</li>
                      </ol>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
// import resources from "@/store/resources"

export default {
  name: "prakerja",
  components: {   },
  data: () => ({
    // model:null,
    // resources: [],
  }),
  created() {
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style scoped>
#tab-ver .v-list-item-group .v-list-item--active {
    color: #f05326;
    border-left: 2px solid;
}
</style>