<template>
  <div>
    <section id="detail-kelas" class="py-5">
      <v-row
        v-show="loadContent"
        class="fill-height"
        align-content="center"
        justify="center"
        style="height:60vh"
      >
        <v-col
          class="text-subtitle-1 text-center color-navy-soft"
          cols="12"
        >
          Tunggu beberapa saat ...
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="primary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
      <div v-show="content" class="col-md-10 mx-auto">
        <v-breadcrumbs large :items="items" class="pb-0 ps-4 pt-0"></v-breadcrumbs>
        <div class="row">
          <div class="col-md-12">
            <div>
              <v-card-text class="">
              <h4 class="headline">{{ this.detailClass.title }}</h4>            
              </v-card-text>
            </div>
          </div>
          <div class="col-md-8 d-none d-md-block">
            <v-card elevation="0">
              <v-card-text class="pt-0 px-0 color-black">
                <div class="d-none d-md-block">                  
                  <img :src="this.information.url" style="width: 100%;object-fit: cover;">
                  <v-tabs id="" v-model="tabs">
                    <v-tab class="text-capitalize">Deskripsi</v-tab>
                    <v-tab class="text-capitalize">Review</v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tabs" class="setting col-md-12">
                    <v-tab-item >
                      <div class="">                                  
                        <h3 class="mb-2 pa-2">Deskripsi Pelatihan</h3>
                        <!-- <div class="pa-2" v-html="this.detailClass.program_benefits" style="color:rgba(0, 0, 0, 0.87)"> -->
                        <vue-show-more-text
                          :text="detailClass.program_benefits"
                          :lines="10"
                          :more-text="more"
                          :less-text="less"
                          additional-content-css="font-size:0.875rem !important;"
                          additional-content-expanded-css="font-size:0.875rem !important;"
                          additional-anchor-css="font-size: 0.875rem !important;font-weight:bold;color:#f05326!important; text-align: center!important;"
                          @click="change"
                        />
                          <!-- {{ this.detailClass.program_benefits }} -->
                        <!-- </div> -->

                        <!-- <h4 class="headline pa-2">Jenjang Usia</h4>
                        <p class="pa-2 fs-18 text-capitalize">
                          {{ this.program.age_level }}
                        </p>  -->                

                        <h3 class="pa-2">Bidang Studi</h3>
                        <p class="pa-2 fs-fix text-capitalize">
                          {{ this.studies.title }}
                        </p> 

                        <!-- <h4 class="headline pa-2">Tujuan Pembelajaran</h4>
                        <p class="pa-2 fs-18 text-capitalize">
                          <ol type="number">
                            <li>Peserta mampu membangun persona diri sebagai content creator pada platform TikTok</li>
                            <li>Peserta mampu mengembangkan channel TikTok</li>
                            <li>Peserta mampu membuat strategi konten/video TikTok untuk memperoleh penghasilan</li>
                            <li>Peserta memahami teknik analisa Youtube</li>
                            <li>Peserta mampu membuat channel dan konten Youtube</li>
                            <li>Peserta mampu memonetisasi channel Youtube</li>
                            <li>Peserta mampu mengoptimalisasikan channel Youtube untuk memperoleh penghasilan</li> 
                          </ol>
                        </p>

                        <h4 class="headline pa-2">Bidang Kompetensi</h4>
                        <p class="pa-2 fs-18 text-capitalize">
                          {{ this.competence.title }}
                        </p> -->                   

                        <h3 class="pa-2">Lembaga Pengajar</h3>
                        <div class="row">
                          <div class="col-md-3">                      
                            <img src="@/assets/images/logo-certif.png" style="width: 100%;object-fit: none;">
                          </div>
                          <div class="col-md-9">                      
                            <p class="pa-2 fs-fix">
                              Yureka Education Center (YEC) berdiri pada tahun 2010 dengan nama awal Eureka Tour, sebuah layanan jasa tour spesialis Kampung Inggris. Saat ini YEC fokus membantu setiap peserta menjadi individu lebih baik. Tidak hanya sebatas menjadi jasa tour, namun juga menjadi konsultan pendidikan dan media yang membantu menjadikan diri lebih baik dengan kemudahan akses informasi dan program pelatihan terkait tes TOEFL, IELTS, Persiapan Kerja dan Pembelajaran Bahasa Inggris Sehari-hari.
                            </p>
                          </div>
                        </div>
                         

                        <h3 class="pa-2 mb-2">Fasilitator</h3>
                        <div class="row">
                          <div v-for="(item, index) in this.detailClass.fasilitator" :key="'A'+ index">                        
                            <div class="col-md-12 ma-auto">                      
                              <img :src="item.picture.url" class="mx-2 text-center" style="width: 120px;height: 120px;border-radius: 50%;object-fit: cover;">
                            </div>
                          </div>
                          <div class="col-md-12" v-for="(item, index) in this.fasilitator" :key="'B'+ index">                      
                            <p class="pa-2 text-capitalize mb-0 fs-fix">
                              {{ item.description }}
                            </p>
                          </div>
                          <!-- <div class="col-md-3">                      
                            <img src="https://storage.googleapis.com/ytc-cdn-prod/prakerja/WhatsApp%20Image%202021-07-08%20at%2018.25.27.jpeg" style="width: 150px;height: 150px;border-radius: 50%;object-fit: cover;">
                          </div>
                          <div class="col-md-9">                      
                            <h3 class="pa-2 fs-18">
                              {{ this.fasilitator.name }}
                            </h3>
                            <p class="pa-2 fs-18">
                              {{  this.fasilitator.description }}
                            </p>
                          </div> -->
                        </div>
                      </div>
                    </v-tab-item>
                    <v-tab-item>
                      <div>
                        <div class="rate col-md-4 text-center">
                          <v-card class="pa-2" color="primary">
                            <h2 class="font-weight-bold display-2">{{this.rate.rating}}</h2>
                            <v-rating
                              v-model="this.rate.rating"
                              color="white"
                              background-color="grey darken-1"
                              empty-icon="$ratingFull"
                              half-increments
                              hover
                              small
                              readonly                          
                            ></v-rating>
                            <p class="text-muted fs-14 font-weight-bold ">{{ this.rate.count }} Review</p> 
                          </v-card>                       
                        </div>
                        <div class="col-md-4">
                          
                        </div>
                        <!-- <v-slide-group
                          multiple
                          show-arrows
                          class="mb-3"
                        >
                          <v-slide-item>
                            <v-btn
                              class="mx-2"
                              color="grey darken-1"
                              outlined
                              rounded
                              small                              
                            >
                              Semua
                            </v-btn>
                          </v-slide-item>
                          <v-slide-item>
                            <v-btn
                              class="mx-2"
                              color="grey darken-1"
                              outlined
                              rounded
                              small                              
                            >
                              <v-icon color="yellow">mdi-star</v-icon> 5
                            </v-btn>
                          </v-slide-item>
                          <v-slide-item>
                            <v-btn
                              class="mx-2"
                              color="grey darken-1"
                              outlined
                              rounded
                              small                              
                            >
                              <v-icon color="yellow">mdi-star</v-icon> 4
                            </v-btn>
                          </v-slide-item>
                          <v-slide-item>
                            <v-btn
                              class="mx-2"
                              color="grey darken-1"
                              outlined
                              rounded
                              small                              
                            >
                              <v-icon color="yellow">mdi-star</v-icon> 3
                            </v-btn>
                          </v-slide-item>
                          <v-slide-item>
                            <v-btn
                              class="mx-2"
                              color="grey darken-1"
                              outlined
                              rounded
                              small                              
                            >
                              <v-icon color="yellow">mdi-star</v-icon> 2
                            </v-btn>
                          </v-slide-item>
                          <v-slide-item>
                            <v-btn
                              class="mx-2"
                              color="grey darken-1"
                              outlined
                              rounded
                              small                              
                            >
                              <v-icon color="yellow">mdi-star</v-icon> 1
                            </v-btn>
                          </v-slide-item>
                        </v-slide-group> -->
                        <v-col md="12">
                          <v-list three-line>
                            <template>                              
                              <v-list-item v-for="(item, index) in this.listReview" :key="'A'+ index">
                                <v-list-item-avatar>
                                  <img src="@/assets/images/no-pict.png">
                                </v-list-item-avatar>

                                <v-list-item-content>
                                  <v-list-item-title>{{ item.member.fullname}}</v-list-item-title>
                                  <div>
                                    <v-icon color="yellow" small v-for="index in item.star" :key="'B'+ index" >mdi-star</v-icon>                                  
                                    <!-- <v-icon color="yellow" small>mdi-star</v-icon>                                  
                                    <v-icon color="yellow" small>mdi-star</v-icon>                   -->                
                                  </div>
                                  <v-list-item-subtitle>{{ item.review }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <!-- <v-list-item-action>
                                  <v-list-item-action-text>2 menit yang lalu</v-list-item-action-text>
                                </v-list-item-action> -->
                              </v-list-item>
                            </template>
                          </v-list>
                        </v-col>
                      </div>
                    </v-tab-item>
                  </v-tabs-items>
                </div>                    
              </v-card-text>
            </v-card>
          </div>
          <div class="col-md-12 pa-0 d-lg-none d-md-none d-sm-block" style="background: #fff;min-height: 80vh;">
            <img :src="this.information.url" style="width: 100%">                  
                  <div class="button-beli pa-2 ps-5">
                    <!-- <span class="fs-14 color-strike"><s>Rp 750,000 </s></span> -->
                    <h2 class="color-state">Rp 499,000</h2>                    
                    <!-- <div class="pa-2 ma-auto text-center mb-3" style="width: 100%;">
                          <v-btn
                            color="primary"
                            rounded
                            class="mx-auto text-center white--text"
                            elevation="2"                          
                            block
                          >
                            Beli Kelas Online
                          </v-btn>
                    </div> -->      
                  </div>

                  <v-tabs v-model="tab" background-color="transparent" grow>
                    <v-tab class="text-capitalize">Deskripsi</v-tab>
                    <v-tab class="text-capitalize">Review</v-tab>
                    <v-tab class="text-capitalize">Aktivitas</v-tab
                    >
                  </v-tabs>

                  <v-tabs-items v-model="tab" class="pa-2 ps-5" >
                    <v-tab-item >
                      <v-card elevation="0" class="bg-transparent pt-5">
                          <div class="">                                  
                            <h3 class="mb-2 pa-2">Deskripsi Pelatihan</h3>
                            <!-- <div class="pa-2" v-html="this.detailClass.program_benefits" style="color:rgba(0, 0, 0, 0.87)">
                            </div> -->
                            <vue-show-more-text
                              :text="detailClass.program_benefits"
                              :lines="10"
                              :more-text="more"
                              :less-text="less"
                              additional-content-css="font-size:0.875rem !important;"
                              additional-content-expanded-css="font-size:0.875rem !important;"
                              additional-anchor-css="font-size: 0.875rem !important;font-weight:bold;color:#f05326!important; text-align: center!important;"
                              @click="change"
                            />

                            <!-- <h4 class="headline pa-2">Jenjang Usia</h4>
                            <p class="pa-2 fs-18 text-capitalize">
                              {{ this.program.age_level }}
                            </p>  -->                

                            <h3 class="pa-2">Bidang Studi</h3>
                            <p class="pa-2 fs-fix text-capitalize">
                              {{ this.studies.title }}
                            </p> 

                            <!-- <h4 class="headline pa-2">Tujuan Pembelajaran</h4>
                            <p class="pa-2 fs-18 text-capitalize">
                              <ol type="number">
                                <li>Peserta mampu membangun persona diri sebagai content creator pada platform TikTok</li>
                                <li>Peserta mampu mengembangkan channel TikTok</li>
                                <li>Peserta mampu membuat strategi konten/video TikTok untuk memperoleh penghasilan</li>
                                <li>Peserta memahami teknik analisa Youtube</li>
                                <li>Peserta mampu membuat channel dan konten Youtube</li>
                                <li>Peserta mampu memonetisasi channel Youtube</li>
                                <li>Peserta mampu mengoptimalisasikan channel Youtube untuk memperoleh penghasilan</li> 
                              </ol>
                            </p>

                            <h4 class="headline pa-2">Bidang Kompetensi</h4>
                            <p class="pa-2 fs-18 text-capitalize">
                              {{ this.competence.title }}
                            </p> -->                   

                            <h3 class="pa-2">Lembaga Pengajar</h3>
                            <div class="row">
                              <div class="col-md-3">                      
                                <img src="@/assets/images/logo-certif.png" style="width: 100%;object-fit: none;">
                              </div>
                              <div class="col-md-9">                      
                                <p class="pa-2 fs-fix">
                                  Yureka Education Center (YEC) berdiri pada tahun 2010 dengan nama awal Eureka Tour, sebuah layanan jasa tour spesialis Kampung Inggris. Saat ini YEC fokus membantu setiap peserta menjadi individu lebih baik. Tidak hanya sebatas menjadi jasa tour, namun juga menjadi konsultan pendidikan dan media yang membantu menjadikan diri lebih baik dengan kemudahan akses informasi dan program pelatihan terkait tes TOEFL, IELTS, Persiapan Kerja dan Pembelajaran Bahasa Inggris Sehari-hari.
                                </p>
                              </div>
                            </div>
                             

                            <h3 class="pa-2 mb-2">Fasilitator</h3>
                            <div class="row">
                              <div v-for="(item, index) in this.detailClass.fasilitator" :key="'A'+ index">                        
                                <div class="col-md-12 ma-auto">                      
                                  <img :src="item.picture.url" class="mx-2 text-center" style="width: 120px;height: 120px;border-radius: 50%;object-fit: cover;">
                                </div>
                              </div>
                              <div class="col-md-12" v-for="(item, index) in this.fasilitator" :key="'B'+ index">                      
                                <p class="pa-2 text-capitalize mb-0 fs-fix">
                                  {{ item.description }}
                                </p>
                              </div>
                              <!-- <div class="col-md-3">                      
                                <img src="https://storage.googleapis.com/ytc-cdn-prod/prakerja/WhatsApp%20Image%202021-07-08%20at%2018.25.27.jpeg" style="width: 150px;height: 150px;border-radius: 50%;object-fit: cover;">
                              </div>
                              <div class="col-md-9">                      
                                <h3 class="pa-2 fs-18">
                                  {{ this.fasilitator.name }}
                                </h3>
                                <p class="pa-2 fs-18">
                                  {{  this.fasilitator.description }}
                                </p>
                              </div> -->
                            </div>
                          </div>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item >
                       <div>
                        <div class="rate col-md-4 text-center">
                          <v-card class="pa-2" color="primary">
                            <h2 class="font-weight-bold display-2">{{this.rate.rating}}</h2>
                            <v-rating
                              v-model="this.rate.rating"
                              color="white"
                              background-color="grey darken-1"
                              empty-icon="$ratingFull"
                              half-increments
                              hover
                              small
                              readonly                          
                            ></v-rating>
                            <p class="text-muted fs-14 font-weight-bold ">{{ this.rate.count }} Review</p> 
                          </v-card>                       
                        </div>
                        <div class="col-md-4">
                          
                        </div>
                        <!-- <v-slide-group
                          multiple
                          show-arrows
                          class="mb-3"
                        >
                          <v-slide-item>
                            <v-btn
                              class="mx-2"
                              color="grey darken-1"
                              outlined
                              rounded
                              small                              
                            >
                              Semua
                            </v-btn>
                          </v-slide-item>
                          <v-slide-item>
                            <v-btn
                              class="mx-2"
                              color="grey darken-1"
                              outlined
                              rounded
                              small                              
                            >
                              <v-icon color="yellow">mdi-star</v-icon> 5
                            </v-btn>
                          </v-slide-item>
                          <v-slide-item>
                            <v-btn
                              class="mx-2"
                              color="grey darken-1"
                              outlined
                              rounded
                              small                              
                            >
                              <v-icon color="yellow">mdi-star</v-icon> 4
                            </v-btn>
                          </v-slide-item>
                          <v-slide-item>
                            <v-btn
                              class="mx-2"
                              color="grey darken-1"
                              outlined
                              rounded
                              small                              
                            >
                              <v-icon color="yellow">mdi-star</v-icon> 3
                            </v-btn>
                          </v-slide-item>
                          <v-slide-item>
                            <v-btn
                              class="mx-2"
                              color="grey darken-1"
                              outlined
                              rounded
                              small                              
                            >
                              <v-icon color="yellow">mdi-star</v-icon> 2
                            </v-btn>
                          </v-slide-item>
                          <v-slide-item>
                            <v-btn
                              class="mx-2"
                              color="grey darken-1"
                              outlined
                              rounded
                              small                              
                            >
                              <v-icon color="yellow">mdi-star</v-icon> 1
                            </v-btn>
                          </v-slide-item>
                        </v-slide-group> -->
                        <v-col md="12">
                          <v-list three-line>
                            <template>                              
                              <v-list-item v-for="(item, index) in this.listReview" :key="'I'+ index">
                                <v-list-item-avatar>
                                  <img src="@/assets/images/no-pict.png">
                                </v-list-item-avatar>

                                <v-list-item-content>
                                  <v-list-item-title>{{ item.member.fullname}}</v-list-item-title>
                                  <div>
                                    <v-icon color="yellow" small v-for="index in item.star" :key="'L'+ index" >mdi-star</v-icon>                                  
                                    <!-- <v-icon color="yellow" small>mdi-star</v-icon>                                  
                                    <v-icon color="yellow" small>mdi-star</v-icon>                   -->                
                                  </div>
                                  <v-list-item-subtitle>{{ item.review }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <!-- <v-list-item-action>
                                  <v-list-item-action-text>2 menit yang lalu</v-list-item-action-text>
                                </v-list-item-action> -->
                              </v-list-item>
                            </template>
                          </v-list>
                        </v-col>
                      </div>
                    </v-tab-item>
                    <v-tab-item >
                      <v-card elevation="0" class="bg-transparent">
                        <div>
                          <v-expansion-panels
                            class="custom-panels overflow-auto"
                            style="max-height: 64vh"
                          >
                            <v-expansion-panel
                              class="rounded-0"
                              v-for="(item, index) in this.detailClass.topic"
                              :key="index"
                            >
                              <v-expansion-panel-header class="px-4">
                                <div>
                                  <div class="mb-1 font-weight-bold">{{ item.name }}</div>
                                  <div class="text-body-2 color-navy-soft">
                                    {{ item.activities.length }} Aktivitas
                                  </div>
                                </div>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content class="navy-soft pa-0">
                                <v-list dense class="col-12 pa-0">
                                  <v-list-item-group>
                                    <v-divider></v-divider>
                                    <!-- <v-list-item
                                      v-for="(i, key) in 5"
                                      :key="i"
                                      class="grey lighten-5 d-block"
                                      color="primary"
                                      @click="
                                        pageType = i;
                                        showActivity = false;
                                      "
                                    > -->
                                    <v-list-item
                                      v-for="(act, i) in item.activities"
                                      :key="i"
                                      class="grey lighten-5 d-block"
                                      color="primary"                            
                                    >
                                      <!-- pageType = act; -->
                                      <v-list-item-content>
                                        <div class="d-flex my-1">
                                          <div style="width: 2rem">{{ i + 1 }}.</div>
                                          <div>
                                            <div
                                              class="
                                                mb-1
                                                font-weight-medium
                                                text-capitalize
                                              "
                                            >
                                              {{ act.title }}
                                            </div>
                                            <div
                                              class="color-navy-soft text-body-2 d-flex"
                                            >
                                              <template
                                                v-if="act.type == 'menonton_video'"
                                              >
                                                <span class="d-flex mr-2">
                                                  <v-icon
                                                    class="navy-soft--text mr-1"
                                                    small
                                                    >ri-film-line</v-icon
                                                  >
                                                  Menonton Video
                                                </span>
                                                <span class="d-flex">
                                                  <v-icon
                                                    class="navy-soft--text mr-1"
                                                    small
                                                    >ri-time-line</v-icon
                                                  >
                                                  00:{{ act.settings.estimated_duration }}:00
                                                </span>
                                              </template>
                                              <template
                                                v-else-if="act.type == 'pre_test' || act.type == 'post_test' || act.type == 'quiz'"
                                              >
                                                <span class="d-flex mr-2">
                                                  <v-icon
                                                    class="navy-soft--text mr-1"
                                                    small
                                                    >ri-survey-line</v-icon
                                                  >
                                                  Mengerjakan Soal
                                                </span>
                                                <span class="d-flex">
                                                  <v-icon
                                                    class="navy-soft--text mr-1"
                                                    small
                                                    >ri-time-line</v-icon
                                                  >
                                                  00:{{ act.settings.estimated_duration }}:00
                                                </span>
                                              </template>
                                              <template v-else-if="act.type == 'praktek_mandiri'">
                                                <span class="d-flex mr-2">
                                                  <v-icon
                                                    class="navy-soft--text mr-1"
                                                    small
                                                    >ri-file-download-line</v-icon
                                                  >
                                                  Praktek Mandiri
                                                </span>
                                                <span class="d-flex">
                                                  <v-icon
                                                    class="navy-soft--text mr-1"
                                                    small
                                                    >ri-time-line</v-icon
                                                  >
                                                  00:{{ act.settings.estimated_duration }}:00
                                                </span>
                                              </template>
                                              <template v-else-if="act.type == 'evaluasi_praktek_mandiri'">
                                                <span class="d-flex mr-2">
                                                  <v-icon
                                                    class="navy-soft--text mr-1"
                                                    small
                                                    >ri-file-upload-line</v-icon
                                                  >
                                                  Evaluasi Praktek Mandiri
                                                </span>
                                                <span class="d-flex">
                                                  <v-icon
                                                    class="navy-soft--text mr-1"
                                                    small
                                                    >ri-time-line</v-icon
                                                  >
                                                  00:{{ act.settings.estimated_duration }}:00
                                                </span>
                                              </template>
                                              <template v-else-if="act.type == 'berdiskusi_daring'">
                                                <span class="d-flex mr-2">
                                                  <v-icon
                                                    class="navy-soft--text mr-1"
                                                    small
                                                    >ri-survey-line</v-icon
                                                  >
                                                  Berdiskusi Daring
                                                </span>
                                                <span class="d-flex">
                                                  <v-icon
                                                    class="navy-soft--text mr-1"
                                                    small
                                                    >ri-time-line</v-icon
                                                  >
                                                  00:{{ act.settings.estimated_duration }}:00
                                                </span>
                                              </template>
                                              <template v-else-if="act.type == 'evaluasi'">
                                                <span class="d-flex mr-2">
                                                  <v-icon
                                                    class="navy-soft--text mr-1"
                                                    small
                                                    >ri-edit-box-line</v-icon
                                                  >
                                                  Evaluasi
                                                </span>
                                                <span class="d-flex">
                                                  <v-icon
                                                    class="navy-soft--text mr-1"
                                                    small
                                                    >ri-time-line</v-icon
                                                  >
                                                  00:{{ act.settings.estimated_duration }}:00
                                                </span>
                                              </template>
                                              <template v-else-if="act.type == 'membaca-e-book'">
                                                <span class="d-flex mr-2">
                                                  <v-icon
                                                    class="navy-soft--text mr-1"
                                                    small
                                                    >ri-file-list-line</v-icon
                                                  >
                                                  Membaca E-book
                                                </span>
                                                <span class="d-flex">
                                                  <v-icon
                                                    class="navy-soft--text mr-1"
                                                    small
                                                    >ri-time-line</v-icon
                                                  >
                                                  00:{{ act.settings.estimated_duration }}:00
                                                </span>
                                              </template>
                                            </div>
                                          </div>
                                        </div>
                                      </v-list-item-content>
                                      <v-divider
                                        style="
                                          position: absolute;
                                          left: 0;
                                          right: 0;
                                          bottom: 0;
                                        "
                                      ></v-divider>
                                    </v-list-item>
                                  </v-list-item-group>
                                </v-list>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                          <div class="button-beli pa-2">
                            <!-- <span class="fs-14 color-strike"><s>Rp 750,000 </s></span>
                            <h2 class="color-state mb-3">Rp 499,000</h2> -->
                            <v-btn
                              color="primary"
                              rounded
                              class="mx-auto text-center btn-beli"
                              block
                              elevation="2"
                            >
                              Beli Kelas Online
                            </v-btn>
                          </div>
                        </div> 
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>   
          </div>
          <div class="col-md-4">
            <v-card class="d-none d-md-block">
              <v-card-text class="pb-0">
                <h2 class="mb-3 color-sale">Aktivitas</h2>
              </v-card-text>
              <div>
                <v-expansion-panels
                  class="custom-panels overflow-auto"
                  style="max-height: 64vh"
                >
                  <v-expansion-panel
                    class="rounded-0"
                    v-for="(item, index) in this.detailClass.topic"
                    :key="index"
                  >
                    <v-expansion-panel-header class="px-4">
                      <div>
                        <div class="mb-1 font-weight-bold">{{ item.name }}</div>
                        <div class="text-body-2 color-navy-soft">
                          {{ item.activities.length }} Aktivitas
                        </div>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="navy-soft pa-0">
                      <v-list dense class="col-12 pa-0">
                        <v-list-item-group>
                          <v-divider></v-divider>
                          <!-- <v-list-item
                            v-for="(i, key) in 5"
                            :key="i"
                            class="grey lighten-5 d-block"
                            color="primary"
                            @click="
                              pageType = i;
                              showActivity = false;
                            "
                          > -->
                          <v-list-item
                            v-for="(act, i) in item.activities"
                            :key="i"
                            class="grey lighten-5 d-block"
                            color="primary"                            
                          >
                            <!-- pageType = act; -->
                            <v-list-item-content>
                              <div class="d-flex my-1">
                                <div style="width: 2rem">{{ i + 1 }}.</div>
                                <div>
                                  <div
                                    class="
                                      mb-1
                                      font-weight-medium
                                      text-capitalize
                                    "
                                  >
                                    {{ act.title }}
                                  </div>
                                  <div
                                    class="color-navy-soft text-body-2 d-flex"
                                  >
                                    <template
                                      v-if="act.type == 'menonton_video'"
                                    >
                                      <span class="d-flex mr-2">
                                        <v-icon
                                          class="navy-soft--text mr-1"
                                          small
                                          >ri-film-line</v-icon
                                        >
                                        Menonton Video
                                      </span>
                                      <span class="d-flex">
                                        <v-icon
                                          class="navy-soft--text mr-1"
                                          small
                                          >ri-time-line</v-icon
                                        >
                                        00:{{ act.settings.estimated_duration }}:00
                                      </span>
                                    </template>
                                    <template
                                      v-else-if="act.type == 'pre_test' || act.type == 'post_test' || act.type == 'quiz'"
                                    >
                                      <span class="d-flex mr-2">
                                        <v-icon
                                          class="navy-soft--text mr-1"
                                          small
                                          >ri-survey-line</v-icon
                                        >
                                        Mengerjakan Soal
                                      </span>
                                      <span class="d-flex">
                                        <v-icon
                                          class="navy-soft--text mr-1"
                                          small
                                          >ri-time-line</v-icon
                                        >
                                        00:{{ act.settings.estimated_duration }}:00
                                      </span>
                                    </template>
                                    <template v-else-if="act.type == 'praktek_mandiri'">
                                      <span class="d-flex mr-2">
                                        <v-icon
                                          class="navy-soft--text mr-1"
                                          small
                                          >ri-file-download-line</v-icon
                                        >
                                        Praktek Mandiri
                                      </span>
                                      <span class="d-flex">
                                        <v-icon
                                          class="navy-soft--text mr-1"
                                          small
                                          >ri-time-line</v-icon
                                        >
                                        00:{{ act.settings.estimated_duration }}:00
                                      </span>
                                    </template>
                                    <template v-else-if="act.type == 'evaluasi_praktek_mandiri'">
                                      <span class="d-flex mr-2">
                                        <v-icon
                                          class="navy-soft--text mr-1"
                                          small
                                          >ri-file-upload-line</v-icon
                                        >
                                        Evaluasi Praktek Mandiri
                                      </span>
                                      <span class="d-flex">
                                        <v-icon
                                          class="navy-soft--text mr-1"
                                          small
                                          >ri-time-line</v-icon
                                        >
                                        00:{{ act.settings.estimated_duration }}:00
                                      </span>
                                    </template>
                                    <template v-else-if="act.type == 'berdiskusi_daring'">
                                      <span class="d-flex mr-2">
                                        <v-icon
                                          class="navy-soft--text mr-1"
                                          small
                                          >ri-survey-line</v-icon
                                        >
                                        Berdiskusi Daring
                                      </span>
                                      <span class="d-flex">
                                        <v-icon
                                          class="navy-soft--text mr-1"
                                          small
                                          >ri-time-line</v-icon
                                        >
                                        00:{{ act.settings.estimated_duration }}:00
                                      </span>
                                    </template>
                                    <template v-else-if="act.type == 'evaluasi'">
                                      <span class="d-flex mr-2">
                                        <v-icon
                                          class="navy-soft--text mr-1"
                                          small
                                          >ri-edit-box-line</v-icon
                                        >
                                        Evaluasi
                                      </span>
                                      <span class="d-flex">
                                        <v-icon
                                          class="navy-soft--text mr-1"
                                          small
                                          >ri-time-line</v-icon
                                        >
                                        00:{{ act.settings.estimated_duration }}:00
                                      </span>
                                    </template>
                                    <template v-else-if="act.type == 'membaca-e-book'">
                                      <span class="d-flex mr-2">
                                        <v-icon
                                          class="navy-soft--text mr-1"
                                          small
                                          >ri-file-list-line</v-icon
                                        >
                                        Membaca E-book
                                      </span>
                                      <span class="d-flex">
                                        <v-icon
                                          class="navy-soft--text mr-1"
                                          small
                                          >ri-time-line</v-icon
                                        >
                                        00:{{ act.settings.estimated_duration }}:00
                                      </span>
                                    </template>
                                  </div>
                                </div>
                              </div>
                            </v-list-item-content>
                            <v-divider
                              style="
                                position: absolute;
                                left: 0;
                                right: 0;
                                bottom: 0;
                              "
                            ></v-divider>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <div class="button-beli pa-2">
                  <!-- <span class="fs-14 color-strike"><s>Rp 750,000 </s></span> -->
                  <h2 class="color-state mb-3">Rp 499,000</h2>
                  <v-btn
                    color="primary"
                    rounded
                    class="mx-auto text-center btn-beli"
                    block
                    elevation="2"
                  >
                    Beli Kelas Online
                  </v-btn>
                </div>
              </div>
            </v-card>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import vueShowMoreText from 'vue-show-more-text'

export default {
  name: "detail-class",
  components: {
    vueShowMoreText
  },
  data() {
    return {
      a:5,
      rate:{},
      loadContent:true,
      content:false,
      tabs:null,
      tab:null,
      items: [
        {
          text: 'Beranda',
          disabled:false,
          color:'primary',
          href: '/',
        },
        {
          text: 'Kelas',
          disabled: true,
          href: '#',
        }
      ],
      admins: [
        ['Membuat Fake Project','ri-film-line','Video','ri-time-line','00:08:00'],
        ['Quiz 1','ri-survey-line','Latihan Soal'],
      ],
      cruds: [
        ['Create', 'mdi-plus-outline'],
        ['Read', 'mdi-file-outline'],
        ['Update', 'mdi-update'],
        ['Delete', 'mdi-delete'],
      ],
      detailClass: {},
      program:{},
      studies:{},
      competence:{},
      fasilitator:{},
      act: {},
      id_act: "",
      id_topic: "",
      information:{},
      listReview:{},

      more:'Lihat Selengkapnya',
      less:'Sembunyikan'
    }
  },
  created() {
    this.getDetailClass();
    this.getReviewClass();
  },
  mounted() {
    // console.log(this.$store.getters.isLoggedIn);
  },
  computed:{
    
  },
  methods: {
    change(showAll) {
      console.log(showAll)
    },
    getDetailClass() {
      this.axios
        .get(`/users/v1/program/detail/${this.$route.params.id}`)
        .then((response) => {
          this.content=true;
          this.loadContent=false;
          let res = response.data.data;
          this.detailClass = res;
          this.information = res.program_information.cover;
          this.program = res.learning_targets;
          this.studies= res.learning_targets.studies;
          this.competence= res.learning_targets.competence;
          this.fasilitator= res.fasilitator;
        });
    },
    getReviewClass() {
      this.axios
        .get(`/program_review/v1/list?program_id=${this.$route.params.id}&sort=created_at&sort_type=desc&is_publish=1`,{
          headers: { "Guest-key": "Bhg67YYts10" },
        })
        .then((response) => {
          this.content=true;
          this.loadContent=false;
          let res = response.data.data;
          this.listReview = res.list;
          this.rate = res.rating;
          
        });
    },
  },
};
</script>

<style>

.list-topik{
  max-height: 300px;
  overflow: auto;
}
#detail-kelas .v-list-group__items{
  background: #F9FAFC;
}
#detail-kelas .v-list-item{
  border-bottom: 1px solid rgba(0,0,0,.12);
}
#detail-kelas .v-tab--active.v-tab:not(:focus):before {
  opacity: 0;
}
@media (max-width: 425px){
  #detail-kelas .v-list-item {
    /*border-bottom: 0px solid rgba(0,0,0,.12);*/
  }
}
</style>