<template>
  <v-card elevation="0" class="transparent px-2">
    <vue-html2pdf
      ref="certifPeserta"
      :enable-download="true"
      :preview-modal="false"
      :show-layout="true"
      :pdf-quality="2"
      :filename="sertificateName"
      pdf-orientation="landscape"
      :manual-pagination="true"
      pdf-content-width="100%"
      @hasDownloaded="hasGenerated($event)"
    >
      <section slot="pdf-content">
        <section
          class="pdf-item"
          :style="{
            backgroundColor: `#FFFFFF`,
            backgroundImage: `url('${require('@/assets/images/bg-certif-graduation.png')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `790px`,
            color: `#000000`,
            position: 'relative'
          }"
        >
          <div style="padding-top: 40px">
            <!-- <div style="margin-bottom: 15px; position: absolute; left: 7rem; bottom: 2rem;">
              <img
                src="@/assets/images/skills-week.png"
                alt=""
                srcset=""
                style="width:230px"
              />
            </div> -->
            <div style="text-align: center;">
              <img
                src="@/assets/images/logo-round.png"
                alt=""
                srcset=""
                style="width:130px"
              />
            </div>
            <div
              style="
                font-family: 'Gentium Book Basic', serif;
                font-style: normal;
                font-weight: bold;
                font-size: 50px;
                line-height: 47px;
                text-transform: uppercase;
                text-align: center;
                letter-spacing: 0.015em;
                margin-bottom: 15px;
              "
            >
              Sertifikat
            </div>
            <div
              style="
                font-family: Roboto;
                font-weight: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                margin-bottom: 15px;
              "
            >
              Diberikan kepada:
            </div>
            <div
              style="
                font-family: 'Gentium Book Basic', serif;
                font-style: normal;
                font-weight: bold;
                font-size: 36px;
                line-height: 47px;
                text-align: center;
                letter-spacing: 0.015em;
                margin-bottom: 15px;
              "
            >
              {{ data.user_name }}
            </div>
            <div
              style="
                font-family: Roboto;
                font-weight: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
              "
            >
              Sebagai Peserta Pelatihan Daring Program :<br>{{ data.program_name }}<br> yang diselenggarakan Bina Insani Migrant Training Center
              <!-- <br> pada tanggal 34 Februari 2003 -->
            </div>
            <div style="display: flex; justify-content: center; margin-top: 40px;">
              <div style="width: auto;">
                <div
                  style="
                    font-family: Roboto;
                    font-style: normal;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16px;
                    text-align: center;
                    margin-bottom: 10px;
                    margin-top: 10px;
                  "
                >
                  Yogyakarta,
                  {{ currentDateTime(data.end_test_at) }}
                </div>
                <div
                  style="
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 14px;
                    text-align: center;
                  "
                >
                  Pimpinan Bina Insani MTC,
                </div>
                <div style="margin-top: 10px; margin-bottom: 10px; text-align: center">
                  <img
                    width="100px"
                    height="auto"
                    src="@/assets/images/ttd.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div
                  style="
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 14px;
                    text-align: center;
                  "
                >
                  Mohammad Rosyidi, S. Ag
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="pdf-item"
          :style="{
            backgroundColor: `#FFFFFF`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `790px`,
            color: `#000000`,
            padding: '0 30px 0 30px',
          }"
        >
          <div style="padding-top: 100px">
            <div
              style="
                font-family: 'Roboto';
                font-style: normal;
                font-weight: bold;
                font-size: 26px;
                line-height: 47px;
                text-transform: uppercase;
                text-align: center;
                letter-spacing: 0.015em;
                margin-bottom: 15px;
              "
            >
              Daftar Nilai
            </div>
            <div
              style="
                font-family: Roboto;
                font-weight: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 24px;
                text-align: start;
                margin-bottom: 5px;
              "
            >
              Program Pelatihan : {{ data.program_name }}
            </div>
            <table style="width: 100%; border-collapse: collapse;">
              <thead>
                <tr>
                  <th rowspan="2" style="border: 1px solid; text-align: center">
                    <div style="font-size: 16px; text-transform: uppercase;">No</div>
                  </th>
                  <th rowspan="2" style="border: 1px solid; text-align: center">
                    <div style="font-size: 16px; text-transform: uppercase;">Unit Kompetensi</div>
                  </th>
                  <th rowspan="2" style="border: 1px solid; text-align: center">
                    <div style="font-size: 16px; text-transform: uppercase;">Nilai</div>
                  </th>
                </tr>
              </thead>
              <tbody v-if="this.topics.length > 0" style="font-size: 12px">
                <tr v-for="(topic, i) in topics" :key="i">
                  <td style="border: 1px solid; text-align: center">{{ i + 1 }}</td>
                  <td style="border: 1px solid; text-align: start; padding: 0 5px 0 5px">{{ topic.name }}</td>
                  <td style="border: 1px solid; text-align: center; padding: 0 5px 0 5px">{{ topic.score }}</td>
                </tr>
                <tr>
                  <td colspan="2" style="border: 1px solid; text-align: right; padding: 0 5px 0 5px">
                    <div style="font-size: 14px; font-weight: bold">Nilai rata-rata</div>
                  </td>
                  <td style="border: 1px solid; text-align: center; padding: 0 5px 0 5px">
                    <div style="font-size: 14px; font-weight: bold">{{ averageValue }}</div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div style="display: flex; justify-content: space-between; margin-top: 10px;">
              <div>
                <div
                  style="
                    font-family: Roboto;
                    font-style: normal;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16px;
                    text-transform: uppercase;
                    text-align: start;
                    margin-bottom: 10px;
                    margin-top: 10px;
                  "
                >
                  Keterangan Nilai :
                  <div style="padding-top: 10px">
                    <div style="padding-top: 4px">Baik Sekali : 90 - 100</div>
                    <div style="padding-top: 4px">Baik : 80 - 89</div>
                    <div style="padding-top: 4px">Cukup : 70 - 79</div>
                    <div style="padding-top: 4px">Kurang : 50 - 69</div>
                  </div>
                </div>
              </div>
              <div style="margin-top: 50px">
                <div
                  style="
                    font-family: Roboto;
                    font-style: normal;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16px;
                    text-align: center;
                    margin-bottom: 10px;
                    margin-top: 10px;
                  "
                >
                  Yogyakarta,
                  {{ currentDateTime(data.end_test_at) }}
                </div>
                <div
                  style="
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 14px;
                    text-align: center;
                  "
                >
                  Pimpinan Bina Insani MTC,
                </div>
                <div style="margin-top: 10px; margin-bottom: 10px; text-align: center">
                  <img
                    width="100px"
                    height="auto"
                    src="@/assets/images/ttd.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div
                  style="
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 14px;
                    text-align: center;
                  "
                >
                  Mohammad Rosyidi, S. Ag
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </vue-html2pdf>
  </v-card>
  
</template>

<script>
import moment from "moment";
import VueHtml2pdf from "vue-html2pdf";

export default {
  components: { VueHtml2pdf },
  data() {
    return {
      showbtn:false,
      false:false,
      output: null,
      data: {},
      topics: [],
      averageValue: 0,
      member: {},
      program: '',
      competence: [],
      timer: '',
      score: '',
      sertificateName: "Sertifikat Bina Insani"
    };
  },
  computed: {
  },
  created() {
    this.getData();
    // this.timer = setInterval(this.$refs.certifPeserta.generatePdf(), 1000)
  },
  mounted(){
    // this.$refs.certifPeserta.generatePdf()
  },
  methods: {
    async getData() {
      this.false=true;
      await this.axios
      .get(`/users/v1/myprogram/detail/${this.$route.params.id}`,{headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY }})
      .then((response) => {
        let res = response.data.data;
        this.topics = res.topics
        this.countAverage()
      })
      await this.axios
      .get(`users/v1/public/order/detail/${this.$route.params.redeem}`,{headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY }})
      .then((response) => {
        let res = response.data.data;
        this.data = res;
        this.showCertif();
      });
    },
    currentDateTime(a) {
      return moment(a).lang("id").format("DD MMMM YYYY");
    },
    countAverage() {
      let score = 0
      this.topics.forEach(el => {
        score += el.score
      });
      let average = score/this.topics.length
      this.averageValue = average
    },
    hasGenerated($event) {
      console.log($event);
    },
    showCertif() {
      this.$refs.certifPeserta.generatePdf();
      // window.location.reload();
    },  
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Gentium+Book+Basic:wght@400;700&display=swap');
@import url("https://fonts.googleapis.com/css?family=Saira+Condensed:700");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

tr:nth-child(even) {
  background-color: #f9fafc;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: 0px solid transparent !important;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: 0px solid transparent !important;
  font-size: 14px;
  font-weight: 600;
  color: #8492a6;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr {
  color: #3c4858;
}
.theme--light.v-data-table .v-data-footer {
  border-top: 0px solid transparent !important;
  padding: 16px !important;
}
.v-data-footer__select {
  justify-content: left !important;
  position: absolute;
  left: 20px;
}
.vue-html2pdf .layout-container[data-v-1fd3ad26] {
  background: #fff !important;
}
.vue-html2pdf tr:nth-child(even) {
  background-color: #fff !important;
}
.style1{
  padding-top: 100px;
  padding-bottom: 188px
}
.style2 {
  padding-top: 100px;
  padding-bottom: 180px;
}
.style3 {
  padding-top: 100px;
  padding-bottom:148px;
}
.style4 {
  padding-top: 100px;
  padding-bottom:116px;
}
.style5 {
  padding-top: 100px;
  padding-bottom:0px;
}
@media print {
  @page {
    size: landscape;
  }
}
</style>