<template>
  <div>
    <section id="banner">
      <v-carousel
        circle
        hide-delimiter-background
        touchless
        :show-arrows="false"
        class="box-slider"
      >
        <v-carousel-item class="" >
          <img src="@/assets/images/banner/fix1.jpg" class="img-banner">
            <!-- <v-img height="100%" max-height="400" src="@/assets/images/banner/1.png"></v-img> -->
        </v-carousel-item>
        <v-carousel-item class="">
          <img src="@/assets/images/banner/fix2.jpg" class="img-banner">          
            <!-- <v-img height="100%" max-height="400" src="@/assets/images/banner/2.png" /> -->
        </v-carousel-item>
        <v-carousel-item class="">
          <img src="@/assets/images/banner/fix3.jpg" class="img-banner">          
            <!-- <v-img height="100%" max-height="400" src="@/assets/images/banner/3.png" /> -->
        </v-carousel-item>
      </v-carousel>
    </section>

    <section id="cta-login" class="py-5">
      <div class="col-md-10 mx-auto">
        <div class="bg-cta-login d-flex align-center">
          <v-card-text class="text-center">
            <h4 class="headline white--text mb-3">
              Jika kamu telah membeli kelas <br />
              di mitra kami silahkan login
            </h4>
            <v-btn rounded class="primary--text" @click="login">
              Login Sekarang
            </v-btn>
          </v-card-text>
        </div>
      </div>
    </section>

    <section id="kategori" class="py-5 row">
      <div class="col-md-10 mx-auto">
        <h2>Pengembangan diri</h2>
        <div class="row">
          <div
            v-for="(item, index) in resources"
            :key="index"
            class="col-xl-3 col-md-3 col-sm-6 col-6"
          >
            <v-card class="mx-auto">
              <router-link
                class=""
                :to="{ name: 'detail-kelas', params: { id: item.id } }"
                @click.stop
              >
                <img
                  src="https://storage.googleapis.com/ytc-cdn-prod/prakerja/tiktok-dan-youtube1.jpg"
                  class="img-kategori"
                />

                <v-card-subtitle class="pa-3 pb-0">
                  <h3 class="text-dots-2">{{item.program.title}}</h3>
                </v-card-subtitle>

                <v-card-text class="pa-3 pt-0">
                  <p class="color-disable mb-0 fs-14 text-dots-2" v-text="item.program.program_benefits"></p>
                  <span v-show="false" class="fs-12 color-strike"><s>Rp 750,000 </s></span>
                  <h3 class="color-state">Rp 499,000</h3>
                </v-card-text>
              </router-link>
              <v-card-actions>
                <v-btn
                  color="primary"
                  rounded
                  class="mx-auto text-center btn-beli"
                  block
                  @click="login"
                >
                  Beli Kelas Online
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import resources from "@/store/resources";
export default {
  name: "public-beranda",
  data: () => ({
    resources: [],
  }),
  created() {
    this.resources = resources;
  },
  mounted() {
    // console.log(this.$store.getters.isLoggedIn);
  },
  methods: {
    login() {
      this.$router.push("/auth/login");
    },
  },
};
</script>

<style>
.box-slider{
  width: 100%;
  /*max-height: 360px!important;*/
  height: auto!important;
}
#banner .v-carousel__controls > .v-item-group {
  position: absolute;
  left: 11% !important;
}
#banner .v-carousel__controls__item {
  margin: 0;
}
#banner .v-image{
  /*max-height: 360px!important;*/
  height: auto!important;

}
#banner .v-carousel__controls{
  bottom:5%!important;

}
.img-banner{
  width: 100%;
  /*max-height: 360px!important;*/
  height: auto!important;

  /*object-fit: contain;*/
}
.bg-cta-login {
  background-image: url("../../assets/images/bg-login.png");
  background-repeat: no-repeat;
  background-position: center;
  height: 240px;
  background-size: cover;
}
.img-kategori {
  width: 100%;
}
/*@media (max-width: 768px) {
  #banner .v-image{
    height: auto!important;
  }
}*/
@media (max-width: 425px) {
  .box-slider {
    height: auto !important;
  }
  #banner .v-btn--icon.v-size--small {
    height: 15px;
    width: 15px;
  }
  #banner .v-btn__content i {
    font-size: 12px !important;
  }
  #banner .v-carousel__controls {
    bottom: -10px;
  }
  .v-carousel__item {
    height: auto !important;
  }
  .img-banner {
    height: auto !important;
  }
  .bg-cta-login {
    background-position: right !important;
    height: auto !important;
    width: 100%;
  }
  #cta-login .headline {
    font-size: 16px !important;
  }
}
@media (max-width: 375px) {
  .btn-beli {
    font-size: 10px !important;
  }
}
</style>