import Vue from 'vue'

const vue_fb = {}
vue_fb.install = function install(Vue, options) {
  (function(d, s, id){
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {return;}
    js = d.createElement(s); js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk'));

  window.fbAsyncInit = function onSDKInit() {
      window.FB.init(options)
      window.FB.AppEvents.logPageView()
      Vue.$FB = window.FB
      window.dispatchEvent(new Event('fb-sdk-ready'))
  }
  Vue.FB = undefined
}

Vue.use(vue_fb, {
  appId: '176119467212390',
  autoLogAppEvents: true,
  xfbml: true,
  version: 'v9.0'
})