<template>
  <v-row align-content="center">
    <v-col class="py-0" md="12">
      <v-row>
        <v-col class="white mx-auto" md="6" style="min-height: 100vh">
          <v-spacer class="py-3"></v-spacer>
          <!-- <router-link
            class="font-weight-medium text-decoration-none px-8"
            tabindex="-1"
            color="primary"
            :to="{ name: 'home' }"
          >
            <span
              ><v-icon color="primary"> mdi-arrow-left</v-icon> Kembali</span
            >
          </router-link> -->
          <v-spacer class="py-5"></v-spacer>
          <v-form class="px-8" @submit.prevent="submit" lazy-validation ref="form" v-model="valid">
            <div class="fs-24 font-weight-bold mb-4">Login dulu yuk</div>
            <div class="color-disable mb-4">
              Belum mempunyai akun ?
              <router-link
                class="font-weight-medium text-decoration-none"
                tabindex="-1"
                color="primary"
                :to="{ name: 'registration' }"
              >
                <span>Daftar sekarang</span>
              </router-link>
            </div>

            <v-alert type="error" text dense prominent v-show="response !== null">{{
              response
            }}</v-alert>
            <v-alert type="success" text dense prominent v-show="responsesukses !== null">{{
              responsesukses
            }}</v-alert>
            <!-- <v-row>
              <v-col class="pb-1">
                <div class="mb-2">
                  <span class="font-weight-medium">Pilih Kategori Pengguna</span>
                </div>
                <div class="col-md-12">                  
                  <v-list class="options">
                    <v-list-item-group
                      class="snow row"
                      v-model="account_type"
                      color="primary"
                    >
                      <v-list-item
                        class="col-md-5 mx-2 mb-4 py-3 px-4 rounded overflow-hidden"
                        v-for="(item, index) in type"
                        :key="index"
                      >
                        <v-list-item-content class="text-center d-block font-weight-bold" v-html="item">
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </div>
              </v-col>
            </v-row> -->
                
            <v-row >
              <v-col class="pb-1">
                <div class="mb-2">
                  <span class="font-weight-medium">Nomor Whatsapp</span>
                  <!-- <span v-else-if="account_type==0" class="font-weight-medium">NISN (Nomor Induk Siswa Nasional)</span> -->
                </div>
                <!-- <v-text-field
                  class="rounded-xl"
                  v-model="email"
                  placeholder="Masukkan email Anda"
                  :rules="emailRules"
                  required
                  outlined
                  hide-details="auto"
                ></v-text-field> -->
                <v-text-field
                  class="rounded-xl"
                  v-model="nomer"
                  placeholder="081234xxx"
                  :rules="nomerRules"
                  required
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="mb-2">
                  <span class="font-weight-medium">Password</span>
                  <router-link                    
                    :to="{ name: 'password-send-phone' }"
                    class="float-right text-decoration-none"
                    tabindex="-1"
                  >
                    Lupa Password?
                  </router-link>
                </div>
                <v-text-field
                  class="rounded-xl"
                  v-model="password"
                  placeholder="Min. 8 karakter"
                  :rules="pswRules"
                  outlined
                  hide-details="auto"
                  required
                  :type="show ? 'text' : 'password'"
                  :append-icon="show ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                  @click:append="show = !show"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="">
                <v-btn
                  large
                  elevation="0"
                  type="submit"
                  color="primary"
                  :loading="submiting"
                  class="rounded-xl"
                  block
                >
                  Masuk
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "login",
  data() {
    return {
      show: false,
      errors: [],
      valid: true,
      nomer: "",
      password: "",
      nomerRules: [(v) => !!v || "Nomor Whatsapp tidak boleh kosong"],
      pswRules: [
        (v) => !!v || "Password tidak boleh kosong",
        (v) => (v && v.length >= 8) || "Password harus minimal 8 karakter",
      ],
      submiting: false,
      response: null,
      responsesukses: null,
      account_type:null,
      type:["Sekolah","Umum"]
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  mounted() {},
  methods: {
    OnGoogleAuthFail(error) {
      console.log(error);
    },
    async loginWithGoogle() {
      const googleUser = await this.$gAuth.signIn();
      // const authCode = await this.$gAuth.getAuthCode();
      // console.log(googleUser);
      this.$store
        .dispatch("login_google", googleUser.uc.id_token)
        .then(() => {
          this.submiting = false;
          this.redirectAfterLogin();
        })
        .catch((error) => {
          this.submiting = false;
          console.error(error);
          this.response = error;
        });
    },
    submit() {
      this.submiting = true;
      if (this.$refs.form.validate()) {
        let phone = this.nomer;
        let password = this.password;

        this.$store
          .dispatch("login", { phone, password })
          .then(async () => {
            this.submiting = false;
            await this.$store.dispatch("get_user");
            this.redirectAfterLogin();
          })
          .catch((error) => {
            this.submiting = false;
            this.$refs.form.reset();
            this.response =error.response.data.message;
            console.error(error);
          });
      } else if (this.password.length < 8) {
        this.response = "password kurang dari 8 character";
        this.submiting = false;
      } else {
        this.submiting = false;
        this.response = "Nomor Whatsapp dan password tidak boleh kosong";
        this.$refs.form.reset();
      }
    },
    redirectAfterLogin() {
      if (localStorage.getItem("ytc_prakerja_redirect") != null) {
        this.$router.push(localStorage.getItem("ytc_prakerja_redirect"));
        localStorage.removeItem("ytc_prakerja_redirect");
        return;
      }
      if (this.user.is_updated_profile) {
        return this.$router.push("/kelas");
      }
      return this.$router.push("/account");
    },
  },
};
</script>

<style>
.google-signin-button {
  color: white;
  background-color: red;
  height: 50px;
  font-size: 16px;
  border-radius: 10px;
  padding: 10px 20px 25px 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
#line {
  width: 100%;
  height: max-content;
  text-align: center;
  position: relative;
}

#line:after {
  content: "";
  width: 100%;
  border-bottom: solid 1px #e0e6ed;
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 1;
}

#line span {
  width: auto;
  background: #fff;
  display: inline-block;
  z-index: 3;
  padding: 0 20px 0 20px;
  position: relative;
  margin: 0;
}
</style>
