<template>
  <video
    ref="videoPlayer"
    class="video-js vjs-fluid vjs-default-skin vjs-big-play-centered"
    @contextmenu.prevent.stop
  />
</template>

<script>
import videojs from "video.js";

export default {
  name: "VideoPlayer",
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      player: null,
    };
  },
  mounted() {
    const vm = this;
    this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {
      console.log("onPlayerReady", this);
    });
    this.$refs.videoPlayer.onended = function() {
      vm.$emit("ended");
    };
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>

<style>
.video-js .vjs-progress-control {
  display: none !important;
}
.video-js .vjs-remaining-time {
  margin-left: auto !important;
}
</style>
