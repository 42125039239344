<template>
  <v-card elevation="0" class="transparent px-2">
    <vue-html2pdf ref="certifPeserta" :enable-download="true" :preview-modal="false" :show-layout="true" :pdf-quality="2"
      :filename="sertificateName" pdf-orientation="landscape" :manual-pagination="true" pdf-content-width="100%"
      @hasDownloaded="hasGenerated($event)">
      <section slot="pdf-content">
        <section class="pdf-item" :style="{
          backgroundImage: `url('${require('@/assets/images/bg-sertif-participant.jpg')}')`,
          backgroundSize: `100% 100%`,
          backgroundPosition: `center center`,
          backgroundRepeat: `no-repeat`,
          width: `100%`,
          height: `790px`,
          color: `#4B4B4B`,
          position: 'relative'
        }">
          <!-- <div :class="{style1: this.competence.length != 0}"> -->
          <div class="style1">
            <div style="margin-bottom: 15px; position: absolute; left: 7rem; bottom: 2rem;">
              <img src="@/assets/images/skills-week.png" alt="" srcset="" style="width:230px" />
            </div>
            <div style="text-align: center;margin-bottom: 15px;">
              <img src="@/assets/images/logo.jpg" alt="" srcset="" style="width:300px" />
            </div>
            <div style="
                  font-family: Roboto;
                  font-style: normal;
                  font-weight: bold;
                  font-size: 32px;
                  line-height: 47px;
                  text-align: center;
                  letter-spacing: 0.015em;
                  margin-top: 10px;
                  margin-bottom: 15px;
                ">
              Sertifikat Kepesertaan
            </div>
            <div style="
                  font-family: Roboto;
                  font-weight: normal;
                  font-size: 16px;
                  line-height: 24px;
                  text-align: center;
                  margin-top: 4px;
                ">
              Diberikan atas keberhasilan mengikuti
              <b>Kursus {{ data.program_name }}</b><br>
              yang diselenggarakan oleh Bina Insani Migrant Training Center
            </div>

            <div style="
                  font-family: Roboto;
                  font-style: normal;
                  font-weight: 900;
                  font-size: 24px;
                  line-height: 27px;
                  text-align: center;
                  margin-top: 65px;
                ">
              <!-- margin-bottom: 30px; -->
              {{ data.user_name }}
            </div>
            <hr style="width: 25%;border:1px solid #9e9e9e; margin: auto;">
            <div style="display: flex;margin-top: 50px;">
              <div style="width: 25%;">
                <div style="
                      font-family: Roboto;
                      font-style: normal;
                      font-weight: 400;
                      font-size: 13px;
                      line-height: 16px;
                      text-align: left;
                      /*margin-top: 20px;*/
                      /*margin-left: 20px;*/
                      position: absolute;
                      margin-top: 25%;
                      margin-left: 10%;
                    ">
                  <!-- <p style="font-weight: bold;font-size: 14px; margin-bottom: 5px; margin-top: 0px;">Kompetensi</p>
                  <div style="padding-left: 0px; margin-left: 0px;" v-for="(item ,index) in this.competence" :key="index">
                    <p style="margin-bottom: 2px;font-size:12px;">
                        {{index+1}}. {{ item }}                   
                    </p>                    
                  </div> -->
                </div>
              </div>
              <div style="width: 50%;">
                <div style="
                      font-family: Roboto;
                      font-style: normal;
                      font-weight: bold;
                      font-size: 16px;
                      line-height: 16px;
                      text-align: start;
                      margin-bottom: 12px;
                      margin-top: 10px;
                    ">
                  Yogyakarta,
                  {{ currentDateTime(data.end_test_at) }}
                </div>
                <div style="
                      font-family: Roboto;
                      font-style: normal;
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 14px;
                      text-align: start;
                    ">
                  Direktur Bina Insani MTC,
                </div>
                <div style="margin-top: 10px; margin-bottom: 10px; text-align: start">
                  <img width="150px" height="auto" src="@/assets/images/ttd-bina-insani.png" alt="" srcset="" />
                </div>
                <div style="
                      font-family: Roboto;
                      font-style: normal;
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 14px;
                      text-align: start;
                    ">
                  Mohammad Rosyidi, S. Ag
                </div>
              </div>
              <div style="width: 25%;">
                <!-- <div
                  style="
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 16px;
                    text-align: left;
                    margin-top: 55px;
                    margin-left: 20px;
                  "
                >
                  <div style="text-align: left;position: absolute;bottom: 20%;">
                    <p style="text-align: left;margin-bottom: 10px;">Diverifikasi oleh : </p>
                    <img
                      src="@/assets/images/img-prakerja.png"
                      alt=""
                      srcset=""
                      style="width:60%;text-align: left;"
                    />
                  </div>
                </div>  -->
              </div>
            </div>
          </div>
        </section>
      </section>
    </vue-html2pdf>
  </v-card>
</template>

<script>
import moment from "moment";
import VueHtml2pdf from "vue-html2pdf";
// import { mapGetters } from "vuex";


export default {
  data() {
    return {
      showbtn: false,
      false: false,
      output: null,
      tampil: false,
      data: {},
      test: '10',
      member: {},
      program: '',
      competence: [],
      styleObject: {
        paddingTop: '100px',
        paddingBottom: '180px'
      },
      styleObject2: {
        paddingTop: '100px',
        paddingBottom: '100px'
      },
      styleObject3: {
        paddingTop: '100px',
        paddingBottom: '80px'
      },
      timer: '',
      sertificateName: "Muhammad Isa Idris"
    };
  },
  computed: {
    // ...mapGetters(["user"]),
  },
  components: { VueHtml2pdf },
  created() {
    this.getData();
  },
  mounted() {
    // this.$refs.certifPeserta.generatePdf()
  },
  methods: {
    getData() {
      this.false = true;
      this.axios
        .get(`users/v1/public/order/detail/${this.$route.params.id}`, { headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY } })
        // .get(`/users/v1/myprogram/list`)
        .then((response) => {
          let res = response.data.data;
          // let prog = response.data.data.program;
          this.data = res;
          // this.member=res.member;
          // this.program=prog.title;
          // this.competence=response.data.data.program.learning_targets.competence;
          // console.log('title',this.competence.length)
          this.showCertif();
        });
    },
    cancelAutoUpdate() { clearInterval(this.timer) },
    reloadPage() {
      window.location.reload();
      this.showbtn = true;
    },
    styleku() {
      console.log('test')
      if (this.test >= 6) {
        return this.styleObject;
      }
      else if (this.test === 7) {
        return this.styleObject2;
      }
      else if (this.test === 8) {
        return this.styleObject3;
      }
      else {
        return this.styleObject3;
      }

    },
    currentDateTime(a) {
      return moment(a).lang("nl").format("L");
    },
    dateBirthTest(a) {
      return a;
    },
    dateExpired(a) {
      let currentDate = moment(a);
      let futureYear = moment(currentDate).add(1, "Y");
      return moment(futureYear).format("MMMM D, YYYY");
    },
    async beforeDownload({ html2pdf, options, pdfContent }) {
      html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .output("blob")
      // .then((blob) => {
      //   let formData = new FormData();
      //   let certif = blob;



      //   // formData.append("file", certif, "Sertifikat.pdf");
      //   // formData.append("source", "upload");
      //   // formData.append("title", "Sertifikat");

      //   // this.$store
      //   //   .dispatch("media/create", formData)
      //   //   .then((res) => {
      //   //     if (res.status == 200) {
      //   //       this.id_certif = res.data.id;
      //   //       this.updateCertif(this.id_certif);
      //   //       console.log("result", this.id_certif);
      //   //     }
      //   //   })
      //   //   .catch((error) => {
      //   //     console.log("error", error);
      //   //   });
      // });
    },
    updateCertif(id) {
      let order_id = this.details.id;
      let sertificate_id = id;

      this.axios
        .put(
          `/users/v1/member/order/update-sertificate`,
          { order_id, sertificate_id },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          let res = response.data.data;
          this.loading = false;
          if (response.status == 200) {
            this.loading = false;
            // this.members = res;
            this.getDetailCertif(sertificate_id);
            console.log("berhasil", res);
          }
        });
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    getDetail(order) {
      // this.$set(order, 'submiting', true);            
      this.id = order;
      this.axios
        .get(`/users/v1/myprogram/detail/${this.id}`)
        .then((response) => {
          // this.loading = false;
          let res = response.data.data;
          // this.loading = false;
          this.detail = res.program;
          console.log('data detail', this.detail)
          this.showCertif(this.id);
        });
    },
    // getDetailMember(member_id) {
    //   // console.log('id member',member_id);
    //   let params = {
    //     id: member_id,
    //   };
    //   this.axios.get(`/users/v1/member/detail`, params).then((response) => {
    //     let res = response.data.data;
    //     this.loading = false;
    //     if (response.status == 200) {
    //       this.loading = false;
    //       this.members = res;
    //       this.generateReport();
    //       console.log("member full", res);
    //     }
    //   });
    // },
    // getDetailCertif(id) {
    //   this.id = id;
    //   this.axios
    //     .get(`/storage/v1/media/user/detail/${this.id}`)
    //     .then((response) => {
    //       let res = response.data.data;
    //       this.loading = false;
    //       if (response.status == 200) {
    //         this.loading = false;
    //         let certificate = res.public_url;
    //         window.open(certificate);
    //         // console.log('detail sertificate', res);
    //       }
    //     });
    // },
    showCertif() {
      this.$refs.certifPeserta.generatePdf();
      // window.location.reload();
    },
    showCertifPeserta(e) {
      console.log(e);
      this.$refs.certifPeserta.generatePdf();
    },
    sortDesc($event) {
      this.dir = $event[0] ? "desc" : "asc";
      this.$emit("refresh-list", this.sort, this.dir);
      console.log("dir", $event);
    },
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Saira+Condensed:700");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

tr:nth-child(even) {
  background-color: #f9fafc;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row),
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row) {
  border-bottom: 0px solid transparent !important;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
  border-bottom: 0px solid transparent !important;
  font-size: 14px;
  font-weight: 600;
  color: #8492a6;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr {
  color: #3c4858;
}

.theme--light.v-data-table .v-data-footer {
  border-top: 0px solid transparent !important;
  padding: 16px !important;
}

.v-data-footer__select {
  justify-content: left !important;
  position: absolute;
  left: 20px;
}

.vue-html2pdf .layout-container[data-v-1fd3ad26] {
  background: #fff !important;
}

.vue-html2pdf tr:nth-child(even) {
  background-color: #fff !important;
}

.style1 {
  padding-top: 100px;
  padding-bottom: 188px
}

.style2 {
  padding-top: 100px;
  padding-bottom: 180px;
}

.style3 {
  padding-top: 100px;
  padding-bottom: 148px;
}

.style4 {
  padding-top: 100px;
  padding-bottom: 116px;
}

.style5 {
  padding-top: 100px;
  padding-bottom: 0px;
}

@media print {
  @page {
    size: landscape;
  }
}
</style>